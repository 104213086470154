import React, { useState } from "react";

// MUI | ANT-D :
import { Button, Input, Upload, Radio, DatePicker, Tooltip } from "antd";

// Assets | ICONS :
import { BsArrowLeftShort, BsDownload } from "react-icons/bs";
import { Calendar, Chart, VideoTime } from "iconsax-react";
import { LocalDiningOutlined } from "@mui/icons-material";
import educationHistory from "Assets/Svgs/educationHistory.svg";
import quranTeach from "Assets/Svgs/quranTeach.svg";
import otherTeach from "Assets/Svgs/otherTeach.svg";
import organization from "Assets/Svgs/organization.svg";
import position from "Assets/Svgs/position.svg";
import { Book, DocumentUpload, DollarSquare, Subtitle, Category, Tag, Eye } from 'iconsax-react';
import { RiInformationLine } from 'react-icons/ri';
import EditIcon from "../../../../../../../Assets/Images/editIcon.png";
import BagIcon from "../../../../../../../Assets/Images/bagIcon.png";


// Components :
import Table from "../../table/Table";

// APIs :
import { AddWorkAPI } from "API/user";
import { DeleteUserWorkAPI } from "API/user";
import { EditWorkAPI } from "API/user";

// Redux :
import { useDispatch } from 'react-redux'
import { refreshAPIsActions } from "Redux/Slice/refreshAPIs";
// Helpers :
import { toast } from "react-toastify";

// CSS :
import "./Work.scss";
import dayjs from "dayjs";

import ConfirmationModel from '../../../../../../../Components/ConfirmationModel/ConfirmationModel';
import { duration } from "moment";




const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    return console.log("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    return console.log("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const WorkExperience = ({ selectedUser }) => {
  let Dispatch = useDispatch();
  const [currentWorkId, setCurrentWorkId] = useState(null);


  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    experienceType: "",
    quranExperience: "",
    otherExperience: "",
    organizationTitle: "",
    positionTitle: "",
    startDate: "",
    endDate: "",
    duration: "",
    progress: "",
    workID: currentWorkId

  });
  const [toggleModel, setToggleModel] = useState("all");
  const allowedFileTypes = ['.doc', '.docx', '.pdf'];
  const [editMode, setEditMode] = useState(false);
  const [formErrors, setFormErrors] = useState({});




  const onStartChange = (date, dateString) => {
    handleData("startDate", dateString);
  };
  const onEndChange = (date, dateString) => {
    handleData("endDate", dateString);
  };

  const handleData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: value ? "" : "This field is required",
    });
  };
  const handleFormData = (event) => {
    let { name, value } = event.target;
    handleData(name, value);
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = ["experienceType", "quranExperience", "otherExperience", "organizationTitle", "positionTitle", "startDate", "endDate", "duration"];
    requiredFields.forEach(field => {
      if (!formData[field]) {
        errors[field] = "This field is required";
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const workFunc = async () => {
    if (!validateForm()) {
      toast.error("Some fields are Missing");

      return;
    }
    setLoading(true);
    let fData = new FormData();
    Object.keys(formData).map((key) => {
      if (formData[key]) {
        fData.append(key, formData[key])
      }
    })
    if (file) {
      fData.append('file', file)
    }
    let res;
    if (editMode) {

      // Call update API here
      res = await EditWorkAPI(selectedUser?._id, formData);
      selectedUser.work = res?.data?.result?.work;
    } else {
      res = await AddWorkAPI(selectedUser?._id, fData);
    } if (res?.error != null) {
      toast.error(res.error)
    } else {
      toast.success(res?.data?.message)
      selectedUser.work = res?.data?.result?.work;
      setFormData({
        experienceType: "",
        quranExperience: "",
        otherExperience: "",
        organizationTitle: "",
        positionTitle: "",
        startDate: "",
        endDate: "",
        duration: "",
        progress: "",
      })
      setFile(null);
      setToggleModel("all");
      Dispatch(refreshAPIsActions.setRefreshUserData())
    }

    setLoading(false)
  };

  const handleUploadChange = (info) => {
    setFile(info?.file?.originFileObj || null);
  };

  const handlePreview = (e) => {
    console.log(e);
    e.preventDefault();
    if (!file?.Location) {
      window.open(URL.createObjectURL(file))
    }
    else {
      window.open(file.Location)
    }
  }

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    roleID: null,
    loading: false
  })

  const handleDeleteRoleConfirmation = (role) => {
    setDeleteConfirmation({
      open: true,
      roleID: selectedUser?._id,
      educationId: role?._id,
      loading: false
    })
  }

  const handleDeleteRole = async () => {
    setDeleteConfirmation({
      ...deleteConfirmation,
      loading: true
    })

    let res = await DeleteUserWorkAPI(deleteConfirmation?.roleID, deleteConfirmation?.educationId)
    selectedUser.work = res?.data?.result?.work;
    if (res.error != null) {
      toast.error(res.error)
    } else {
      toast.success(res.data?.message)
    }
    setDeleteConfirmation({
      open: false,
      roleID: null,
      loading: false
    })
  }
  const handleNotDeleteRole = () => {
    setDeleteConfirmation({
      open: false,
      roleID: null,
      loading: false
    })
  }
  const handleEdit = (work) => {
    const educationid = work._id
    setCurrentWorkId(work._id);
    setFormData({


      experienceType: work.experienceType,
      positionTitle: work.positionTitle,
      organizationTitle: work.organizationTitle,
      otherExperience: work.otherExperience,
      startDate: work.startDate,
      endDate: work.endDate,
      quranExperience: work.quranExperience,
      duration: work.duration,
      progress: work.progress,
      image: work.image,
      workID: educationid,
      file: setFile(work.image || null),





    });
    setToggleModel("add");
    setEditMode(true);
  };
  const edit = <span>Edit</span>;
  const remove = <span>Delete</span>;


  const columns = [
    {
      title: 'Experience Type',
      dataIndex: 'experienceType',
      key: 'experienceType',
    },
    {
      title: 'Organization Title',
      dataIndex: 'organizationTitle',
      key: 'organizationTitle',
    },
    {
      title: 'Position Title',
      dataIndex: 'positionTitle',
      key: 'positionTitle',
    },

    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Still In Progress',
      dataIndex: 'progress',
      key: 'progress',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      align: "center",
      render: (_, data) => data?.id != 1 && <>
        <div className="actionBox">
          {
            // (UserData?.isSuperAdmin || RoutePermissions?.includes("edit")) &&
            <Tooltip placement="top" title={edit}>
              <div className="actionBtn" onClick={() => handleEdit(data)}  >
                <img src={EditIcon} alt="" className='icon cursor' />
              </div>
            </Tooltip>
          }
          {
            // (UserData?.isSuperAdmin || RoutePermissions?.includes("delete")) &&

            <Tooltip placement="top" title={remove}>
              <div className="actionBtn" onClick={() => handleDeleteRoleConfirmation(data)}
              >
                <img src={BagIcon} alt="" className='icon cursor' />
              </div>
            </Tooltip>
          }
        </div>
      </>

    },

  ];

  const shortenHeading = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };


  return (
    <>
      <div className="WorkMainConatiner">
        {
          toggleModel == "all" ?
            <div className="allWorkContainer">
              <div className="flexLineSpace">
                <div className="heading upper">
                  Work Records
                </div>
                <div className="buttonandFilter">
                  <Button className='dashboardBtn' style={{
                    width: "120px",
                    background: "#0a1c29",
                  }} onClick={() => setToggleModel("add")}> Add Record </Button>
                </div>
              </div>
              <div className="table">
                <Table columns={columns} rows={selectedUser?.work} />
              </div>
              <ConfirmationModel open={deleteConfirmation.open} onOk={handleDeleteRole} onCancel={handleNotDeleteRole} confirmLoading={deleteConfirmation.loading}>
                <div className="deleteModel">
                  <div className="titleBox">
                    <RiInformationLine className='icon' /> <div className="title"> Are you sure you want to delete this Record? </div>
                  </div>
                </div>
              </ConfirmationModel>
            </div>
            :
            <div className="addWorkContainer">
              <div className="EducationHistoryHeading">
                <div className="eduDiv">
                  <BsArrowLeftShort className='icon' onClick={() => setToggleModel("all")} />
                  <img src={educationHistory} alt="educationHistory" />
                  <h1 className="heading">Work Experience</h1>
                </div>
              </div>
              <div className="InputFields">
                <div className="Inputfield">
                  <div className="field1 field">
                    <div className="educationField eduInput">
                      <h1 className="TypeHeading">Experience Type</h1>
                      <div>
                        <Radio.Group name="experienceType" value={formData?.experienceType} onChange={handleFormData} defaultValue={formData.experienceType}>
                          <Radio value="Quran Teaching">Quran Teaching</Radio>
                          <Radio value="Other Teaching">Other Teaching</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Inputfield">
                  <div className="field1 field">
                    <div className="field1 field">
                      <div className="lableName">Quran Teaching Experience</div>
                      <img className="degreeImage2" src={quranTeach} alt="Degree" />
                      <Input
                        required
                        size="large"
                        className="eduInput"
                        type="text"
                        placeholder="Quran Teaching Experience"
                        name="quranExperience"
                        value={formData?.quranExperience}
                        onChange={handleFormData}
                      />

                    </div>
                    {formErrors.quranExperience && <p className="error">{formErrors.quranExperience}</p>}

                  </div>
                  <div className="field1 field">  <div className="field2 field">
                    <div className="lableName">Other Teaching Experience</div>
                    <img className="degreeImage2" src={otherTeach} alt="Degree" />
                    <Input
                      required
                      size="large"
                      className="eduInput"
                      type="text"
                      placeholder="Other Teaching Experience"
                      name="otherExperience"
                      onChange={handleFormData}
                      value={formData?.otherExperience}
                    />
                    <div className="errorclass">

                    </div>
                    {formErrors.otherExperience && <p className="error">{formErrors.otherExperience}</p>}

                  </div></div>

                </div>
                <div className="Inputfield">
                  <div className="field1 field">
                    <div className="field1 field">
                      <div className="lableName">Organization Title</div>
                      <img className="degreeImage2" src={organization} alt="Degree" />
                      <Input
                        required
                        size="large"
                        className="eduInput"
                        type="text"
                        placeholder="Organization Title"
                        name="organizationTitle"
                        onChange={handleFormData}
                        value={formData?.organizationTitle}
                      />

                    </div>
                    {formErrors.organizationTitle && <p className="error">{formErrors.organizationTitle}</p>}

                  </div>

                  <div className="field2 field">

                    <div className="field2 field">

                      <div className="lableName">Position Title</div>
                      <img className="degreeImage2" src={position} alt="Degree" />
                      <Input
                        required
                        size="large"
                        className="eduInput"
                        type="text"
                        placeholder="Position Title"
                        name="positionTitle"
                        onChange={handleFormData}
                        value={formData?.positionTitle}

                      />

                    </div>
                    {formErrors.positionTitle && <p className="error">{formErrors.positionTitle}</p>}

                  </div>
                </div>
                <div className="Inputfield">
                  <div className="field1 field">
                    <div className="field1 field">
                      <div className="lableName">Start Date</div>
                      <Calendar className="degreeImage2" />
                      <DatePicker
                        required
                        size="large"
                        className="eduInput"
                        type="date"
                        placeholder="Select Start Date"
                        name="startDate"
                        value={formData?.startDate ? dayjs(formData?.startDate) : null}
                        onChange={onStartChange}
                      />

                    </div>
                    {formErrors.startDate && <p className="error">{formErrors.startDate}</p>}

                  </div>
                  <div className="field2 field">
                    <div className="field2 field">
                      <div className="lableName">End Date</div>
                      <Calendar className="degreeImage2" />
                      <DatePicker
                        required
                        size="large"
                        className="eduInput"
                        type="date"
                        placeholder="Select End Date"
                        name="endDate"
                        value={formData?.endDate ? dayjs(formData?.endDate) : null}
                        onChange={onEndChange}
                      />

                    </div>
                    {formErrors.endDate && <p className="error">{formErrors.endDate}</p>}

                  </div>
                </div>
                <div className="Inputfield">
                  <div className="field1 field">
                    <div className="field1 field">
                      <div className="lableName">Duration in Year</div>
                      <VideoTime className="degreeImage2" />
                      <Input
                        required
                        size="large"
                        className="eduInput"
                        placeholder="Duration in Year"
                        name="duration"
                        onChange={handleFormData}
                        type="text"
                        value={formData?.duration}
                      />
                    </div>
                    {formErrors.duration && <p className="error">{formErrors.duration}</p>}

                  </div>
                  <div className="field2 field">
                    <div className="lableName">Still In Progress</div>
                    <div className="progressInput">
                      {<Chart />}
                      <h1 className="progressHeading ">Still In Progress</h1>
                      <div>
                        <Radio.Group

                          name="progress"
                          onChange={handleFormData}
                          value={formData?.progress}
                          className="progressRadio"
                        >
                          <Radio value="Yes">Yes</Radio>
                          <Radio value="No">No</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field2 field3 field" style={{ marginTop: '1%' }}>
                  <div className="labelName" style={{ marginBottom: '1%' }}>Upload Documents</div>

                  <div className="uploadBtn">
                    <div className="icon">
                      <BsDownload style={{ color: "black" }} />
                      {file ? shortenHeading(file?.Location, 50) : "Attach File"}
                    </div>

                    {file && (
                      <div className="iconEye" onClick={handlePreview}>
                        <Eye size={26} color="#000" />
                      </div>
                    )}

                    <Upload
                      name="image"
                      required
                      className="upload"
                      showUploadList={false}
                      accept={allowedFileTypes.join(',')}
                      onChange={handleUploadChange}
                    >
                      <DocumentUpload />
                    </Upload>

                  </div>
                </div>

                <div className="saveButton">
                  <Button className="yellowGraBtn btn" loading={loading} onClick={workFunc}>Save</Button>
                </div>

              </div>
            </div>
        }
      </div>
    </>
  );
};

export default WorkExperience;
